export const navLinks = [
  {
    name: "HOME",
    link: "/",
  },
  {
    name: "ABOUT",
    link: "/about",
  },
  {
    name: "SERVICES",
    link: "/services",
  },
  {
    name: "PROJECT",
    link: "/project",
  },
  {
    name: "BLOG",
    link: "/blog",
  },
  {
    name: "CONTACT",
    link: "/contact",
  },
];

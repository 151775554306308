import { Routes, Route } from "react-router-dom";
import {
  HomePage,
  ServicePage,
  ProjectPage,
  ContactPage,
  AboutPage,
} from "./LazyPages";
import React from "react";
import Header from "../components/pages/header/Header";
import Footer from "../components/pages/footer/Footer";

export default function RoutesComponents() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
      <Footer />
    </>
  );
}

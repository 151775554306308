import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Suspense } from "react";
import RoutesComponents from "./routes";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState, useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
AOS.init();
function App() {
  const [progress, setProgress] = React.useState(10);

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              background: "#000",
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <RoutesComponents />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

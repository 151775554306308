import React from "react";
import "./Footer.css";
import { ReactComponent as ArrowUpIcon } from "../../../assets/icons/arrow-up.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook-white.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/instagram-white.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/icons/youtube-white.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitter-white.svg";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <div className="footer-main-container">
      <div className="footer-heading-button">
        <div className="footer-h2">
          Have a project in mind? <br />
          Let's get to work
        </div>
        <div>
          <button>
            <Link to="/contact">Contact Us</Link>
          </button>
        </div>
      </div>
      <div className="border-line">
        <hr />
      </div>
      <div className="footer-bottom-container">
        <div className="footer-content">
          <div className="footer-left-box">
            <div className="footer-creative-agency">
              An influencal creative agency. <br /> Get’s IT Solutions For
              Expert <br /> Consultants
            </div>
            <div className="footer-lets-chat">
              <div>Let's Chat </div>
              <div>
                <IconButton>
                  <ArrowUpIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="footer-nav-links">
            <div>
              <Link to="/about">About us</Link>
            </div>
            <div>
              <Link to="/contact">Contact</Link>
            </div>
            <div>
              <Link to="/blog">Blog</Link>
            </div>
            <div>
              <Link to="/policy">Policy</Link>
            </div>
            <div>
              <Link to="/refund">Refund</Link>
            </div>
          </div>
          <div className="footer-left-box">
            <div className="footer-contact-email">
              <a href="mailto:info@jtechsight.com">Info@Jtechsight.com</a>
            </div>
            {/* <div><span>Karachi, Pakistan</span></div> */}
            <div>
              <span>Terms & Conditions</span>
            </div>
            <div>
              <span>Privacy Policy</span>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className="footer-socials-and-rights">
        <div className="footer-content-box">
          <div className="footer-jtech-rights">
            Jtechsight&#169;2022, All Rights Reserved
          </div>
          <div className="footer-socials-links">
            <div>
              <IconButton>
                <FacebookIcon />
              </IconButton>
            </div>
            <div>
              <IconButton>
                <TwitterIcon />
              </IconButton>
            </div>
            <div>
              <IconButton>
                <InstagramIcon />
              </IconButton>
            </div>
            <div>
              <IconButton>
                <YoutubeIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

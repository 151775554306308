import React, { useEffect, useState } from "react";
import "./Header.css";
import JtechLogo from "../../../assets/icons/jtechsight_whitecolor.webp";
import { ReactComponent as MenuBtn } from "../../../assets/icons/menu.svg";
import { navLinks } from "../../../dummy-data/navlinksdata";
import "animate.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Paper from "@mui/material/Paper";

export default function Header() {
  const [showBurger, setShowBurger] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
    setDrawerOpen(false);
  };
  useEffect(() => {
    function updateSize() {
      if (window.innerWidth < 950) {
        setShowBurger(true);
      } else {
        setShowBurger(false);
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <div className="header-main-container">
      <div className="jtech-logo" data-aos="fade-right">
        <img src={JtechLogo} alt="jtechlogo" onClick={handleClick} />
      </div>
      {showBurger ? (
        <div className="future">
          <div>{/* <SearchBtn /> */}</div>
          <div className="burger-icon" data-aos="fade-left">
            <IconButton onClick={handleDrawerOpen}>
              <MenuBtn />
            </IconButton>
          </div>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
          >
            <div
              className="close-drawer-x"
              onClick={() => setDrawerOpen(false)}
            >
              <span>X</span>
            </div>
            <Paper className="mobile-drawer">
              <div className="jtech-logo">
                <img
                  src={JtechLogo}
                  alt="jtechlogo"
                  onClick={() => handleClick}
                />
              </div>
              <List>
                {navLinks.map(({ link, name }) => (
                  <ListItem
                    key={link}
                    onClick={() => {
                      navigate(link);
                      handleDrawerClose();
                    }}
                  >
                    <ListItemText
                      className="button-links animate__animated animate__backInRight "
                      primary={name}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Drawer>
        </div>
      ) : (
        <div className="nav-links" data-aos="fade-left">
          {navLinks.map(({ link, name }) => (
            <Link to={link} key={link}>
              {name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
